import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from "../components/seo"
import BookingForm from "../components/BookingForm"
import PostList from '../components/PostList'
//import BlogTagList from '../components/BlogTagList'
import PageBanner from '../components/PageBanner'
import banner_image from '../images/page-banners/activities.jpg'

const BlogPage =({data}) => {

  const {posts, tags} = data

  let uniq_tags_list = []
  let uniq_tag_slug_list = []

  tags.edges.map(({node}) => {
    node.frontmatter.tags.map(tag => {
      if(!uniq_tag_slug_list.includes(tag.slug)) {
        uniq_tag_slug_list.push(tag.slug)
        uniq_tags_list.push(tag)
      }

      return true;
    })

    return true;
  });

  return (

    <Layout sidebarComponent={<BookingForm colWidth="col-md-12"/>} className="blog-page">
      
      <SEO title="Blog" />

      <PageBanner image={banner_image} title="Blog"/>

      <div className="inner-content">
        <PostList posts={posts.edges}/>
      </div>

    </Layout>
    
  )
}

export default BlogPage

export const blogPageQuery = graphql`
  query blogPageQuery {
    posts:
      allMarkdownRemark (
        filter: {
          frontmatter: {type: {eq: "Blog"}}
        }
        sort: { order: DESC, fields: [frontmatter___date]}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              type
              excerpt
              path
              date
              tags {
                name
                slug
              }
              thumb {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 470, maxHeight: 270) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    tags:
      allMarkdownRemark (
        filter: {
          frontmatter: {type: {eq: "Blog"}}
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              tags {
                name
                slug
              }
            }
          }
        }
      }
  }
`







