import React from 'react'
import { Link } from 'gatsby'
import BgImage from '../BgImage'
import uuid from 'uuid'

import './style.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const PostList = ({posts}) => (
  <div className="row posts-list">
    {posts.map(({node}) => (
      <div className="col-md-4 post-list-item" key={uuid.v4()}>
        <Link to={node.frontmatter.path}>
          <BgImage image={node.frontmatter.thumb} className="post-list-pic" />
        </Link>
        <div className="post-list-info">
          <Link to={node.frontmatter.path}>
            <h2>{node.frontmatter.title}</h2>  
          </Link>
          <div className="post-date"><FontAwesomeIcon icon={faClock} /> {node.frontmatter.date}</div>
          <p className="post-excerpt">{node.frontmatter.excerpt}</p>
          <Link to={node.frontmatter.path} className="read-more arrow-link">Read more <span>⟶</span></Link>
        </div>
      </div>
    ))}
  </div>
)

export default PostList